/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Row, Col } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { useHistory } from "react-router-dom";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  // PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { AlertList } from "react-bs-notifier";
import Box from "@material-ui/core/Box";
import DialogHapusBatal from "./DialogHapusBatal";
import API from "../../API";
import $ from "jquery";
import bsCustomFileInput from "bs-custom-file-input";
import { getFeeMitra } from "../../../functions/invoice";
import DialogRejectOrderVoucher from "./DialogRejectOrderVoucher";

const formatter = new Intl.NumberFormat("de-DE");

const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    // backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "bold",
    minWidth: 80,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#ffffff",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffffff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#f4a240",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailVoucherVerifikasi(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [setOpen] = React.useState(false);
  const history = useHistory();
  const [alerts, setAlerts] = React.useState([]);
  const [alerts1, setAlerts1] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [informan, setInforman] = React.useState();
  const [mmle_id, setMmleid] = React.useState();
  const [mper_id, setMperid] = React.useState();
  const [tbpkb_vplate, setTbpkb_vplate] = React.useState();
  const [approveOrder, setApproveOrder] = React.useState(false);
  const [rejectOrder, setRejectOrder] = React.useState(false);
  // const [carprice, setCarprice] = React.useState(null);
  // const [bikeprice, setBikeprice] = React.useState(null);
  // const tvoctype = props.tvoctype;
  const type = props.type;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (props.tvoc_id !== null && props.tpnmBPKBID !== null) {
      RequestPenemuan();
    }
  });

  const Verifikasi = () => {
    handleClose2();
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/VoucherTranskasi/simpanVouhcer/" + props.tvoc_id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data:
        '{\n\t"mmle_id": ' +
        mmle_id +
        ',\n\t"mper_id": ' +
        mper_id +
        ',\n\t"tbpkb_vplate": ' +
        `"${tbpkb_vplate}"` +
        "\n}",
    };

    var verify = {
      async: true,
      crossDomain: true,
      url: API.Foundingapi + "v1/penemuan/simpanTpnm",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data:
        '{\n\t"mmle_id": ' +
        mmle_id +
        ',\n\t"mper_id": ' +
        mper_id +
        ',\n\t"tbpkb_vplate": ' +
        `"${tbpkb_vplate}"` +
        "\n}",
    };

    if (informan == false) {
      setAlerts1([
        {
          id: 1,
          type: "info",
          // headline: "Berhasil",
          message: "Mohon Pilih Cek Keaslian STNK",
        },
      ]);
      setTimeout(() => {
        setAlerts1([]);
      }, 3000);
    } else {
      $.ajax(settings)
        .done(function(response) {
          RequestPenemuan();
          setLoad(true);
        })
        .fail(function(response) {
          console.log(response);
        });

      $.ajax(verify)
        .done(function(response) {
          console.log(response);
          RequestPenemuan();
          setLoad(true);
          setAlerts1([
            {
              id: 1,
              type: "success",
              headline: "Berhasil",
              message: "Voucher Telah Diverifikasi",
            },
          ]);
          setTimeout(() => {
            setAlerts1([]);
          }, 3000);
        })
        .fail(function(response) {
          console.log(response);
          setAlerts1([
            {
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Terjadi Kendala [Error Code " + response.status + "]",
            },
          ]);
          setTimeout(() => {
            setAlerts1([]);
          }, 3000);
        });
    }
  };

  function RequestPenemuan() {
    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Foundingapi + "v1/penemuan/getPenemuanMasterBPKB/" + props.tpnmBPKBID,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    }).done(function(response) {
      if (response.data !== null) {
        // Data Konsumen
        $("#nama_konsumen").val(response.data.nama_konsumen);
        $("#alamat_konsumen").val(response.data.alamat_konsumen);
        $("#no_hp_konsumen").val(response.data.no_hp_konsumen);
        $("#nominal_pelunasan_konsumen").val(formatter.format(parseInt(response.data.nominal_pelunasan_konsumen)));
        $("#fee_informan").val(formatter.format(parseInt(response.data.fee_informan)));
        $("#photo_bukti_transfer").attr('href',response.data.photo_bukti_transfer);
        $("#photo_kendaraan1").attr('href',response.data.photo_kendaraan1);
        $("#photo_kendaraan2").attr('href',response.data.photo_kendaraan2);
        $("#tanggal_transfer").val(response.data.tanggal_transfer);
        $("#sisa_denda").val(formatter.format(parseInt(response.data.sisa_denda)));
        $("#sisa_hutang").val(formatter.format(parseInt(response.data.sisa_hutang)));
        $("#sisa_pelunasan").val(formatter.format(parseInt(response.data.sisa_pelunasan)));
        $("#nominal_transfer").val(formatter.format(parseInt(response.data.nominal_transfer)));

        //Dokumen Verifikasi
        $("#mmle_name").val(response.data.mmle_name);
        $("#mper_phone").val(response.data.mper_phone);
        $("#mper_id").val(response.data.mper_id);
        $("#mmle_id").val(response.data.mmle_id);
        setMmleid(response.data.mmle_id);
        setMperid(response.data.mper_id);
        $("#mmle_name_verif").val(response.data.mmle_name);
        $("#mper_phone_verif").val(response.data.mper_phone);
        response.data.status_penemuan == 4 ? setHide(true) : setHide(false);

        // if( response.data.status_penemuan != 5){
        //   $('#idReject').show('slow');
        //   $('#idApprove').show('slow');
        //   $('#idVerifikasi').hide('slow');
        // }else{
        //   $('#idReject').hide('slow');
        //   $('#idApprove').hide('slow');
        //   $('#idVerifikasi').show('slow');
        // }
      }
    });
    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "v1/uploadBpkb/getTpmBpkb/" + props.tpnmBPKB,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    }).done(function(response) {
      if (response.data !== null) {
        // Data Kendaraan
        $("#tbpkb_vplate").val(response.data.tbpkb_vplate);
        $("#tbpkb_rangka").val(response.data.tbpkb_rangka);
        $("#tbpkb_mesin").val(response.data.tbpkb_mesin);
        $("#tbpkb_vtype").val(response.data.tbpkb_vtype);
        $("#tbpkb_vbrand").val(response.data.tbpkb_vbrand);
        $("#tbpkb_vdoc_name").val(response.data.tbpkb_vdoc_name);
        $("#tbpkb_mesin").val(response.data.tbpkb_mesin);
        $("#tbpkb_vcolor").val(response.data.tbpkb_vcolor);
        $("#tbpkb_vyear").val(response.data.tbpkb_vyear);
        $("#tbpkb_ptype").val(response.data.tbpkb_ptype);

        //Profile Debitur
        $("#tbpkb_debitur_name").val(response.data.tbpkb_debitur_name);
        $("#tbpkb_address").val(response.data.tbpkb_address);
        $("#tbpkb_ktp_debitur").val(response.data.tbpkb_ktp_debitur);
        $("#tbpkb_kk_debitur").val(response.data.tbpkb_kk_debitur);
        $("#tbpkb_created_date").val(response.data.tbpkb_created_date);

        //Rincian Biaya
        $("#tbpkb_base_price").val(formatter.format(parseInt(response.data.tbpkb_base_price)));
        $("#tbpkb_bp_total").val(formatter.format(parseInt(response.data.tbpkb_bp_total)));
        $("#tbpkb_bunga").val(formatter.format(parseInt(response.data.tbpkb_bunga)));
        $("#tbpkb_denda").val(formatter.format(parseInt(response.data.tbpkb_denda)));
        $("#tbpkb_discount").val(formatter.format(parseInt(response.data.tbpkb_discount)));
        $("#tbpkb_fee_informan").val(formatter.format(parseInt(response.data.tbpkb_fee_informan)));
        $("#tbpkb_pelunasan_debitur").val(formatter.format(parseInt(response.data.tbpkb_pelunasan_debitur)));
        $("#tbpkb_pelunasan_leasing").val(formatter.format(parseInt(response.data.tbpkb_pelunasan_leasing)));
        $("#tbpkb_refund").val(formatter.format(parseInt(response.data.tbpkb_refund)));
        $("#tbpkb_upping").val(formatter.format(parseInt(response.data.tbpkb_upping)));
        setTbpkb_vplate(response.data.tbpkb_vplate);
      }
    });
  }

  function Approve() {
    handleClose();
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/VoucherTranskasi/approver/" + props.tvoc_id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        props.close();
        setLoad(true);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Voucher Telah Diapprove",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          history.push("/voucherverifikasi");
        }, 1000);
      })
      .fail(function(response) {
        console.log(response);
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function Reject() {
    handleClose1();
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/VoucherTranskasi/reject/" + props.tvoc_id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        props.close();
        setLoad(true);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Voucher Telah Direject",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          history.push("/voucherverifikasi");
        }, 1000);
      })
      .fail(function(response) {
        console.log(response);
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function ApproveOrder() {
    handleCloseApproveOrder();
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Foundingapi + "v1/Penemuan/approvePenemuan/" + props.tpnmBPKBID,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setLoad(true);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Order Telah Diapprove",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          // history.push("/siapdibayar");
        }, 1000);
      })
      .fail(function(response) {
        console.log(response);
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function RejectOrder() {
    handleCloseRejectOrder();
    const data = {
      keterangan: $("#reject_order_voucher [name=keterangan]").val(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: API.Foundingapi + "v1/Penemuan/rejectPenemuan/" + props.tpnmBPKBID,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data: JSON.stringify(data),
    };
    $.ajax(settings)
      .done(function(response) {
        props.close();
        setLoad(true);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Order Telah Direject",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          // history.push("/voucherreject");
        }, 1000);
      })
      .fail(function(response) {
        console.log(response);
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function handleClickApproveOrder() {
    setApproveOrder(true);
  }

  function handleCloseApproveOrder() {
    setApproveOrder(false);
  }

  function handleClickRejectOrder() {
    setRejectOrder(true);
  }

  function handleCloseRejectOrder() {
    setRejectOrder(false);
  }

  function handleClickOpen1() {
    setOpen1(true);
  }

  function handleClose1() {
    setOpen1(false);
  }

  function handleClickOpen2() {
    setOpen2(true);
    setLoad(false);
  }

  function handleClose2() {
    setOpen2(false);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function UploadBAST(e) {
    console.log(e.target.id);
    var input = e.target.id;
    var key, message, kop;

    console.log(input == "foto_revbast_add");
    console.log(input == "foto_revstnk_add");

    if (input == "foto_revbast_add") {
      key = "revisi_bstk";
      message = "BAST";
      kop = "Revisi";
    } else if (input == "foto_revstnk_add") {
      key = "revisi_stnk";
      message = "STNK";
      kop = "Revisi";
    } else if (input == "foto_revm1_add") {
      key = "revisi_foto_sisi1";
      message = "Foto Sisi Motor 1";
      kop = "Revisi";
    } else if (input == "foto_revm2_add") {
      key = "revisi_foto_sisi2";
      message = "Foto Sisi Motor 2";
      kop = "Revisi";
    } else if (input == "bukti_biaya_kirim_add") {
      key = "bukti_biaya_kirim";
      message = "Bukti Biaya Kirim";
      kop = "File";
    }

    var form = new FormData();
    form.append("upload_data", e.target.files[0]);

    var settings = {
      async: true,
      crossDomain: true,
      url: API.Foundingapi + `/v1/Founding/upload_photos/${key}/${props.id}`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      data: form,
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `${kop} ${message} Telah Diupload`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function HapusBAST(e) {
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_bast_backup`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Revisi BAST Telah Dihapus`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function HapusSTNK() {
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_delivered_stnk_photo`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Revisi STNK Telah Dihapus`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function HapusM1() {
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_vphoto_sisi1`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Revisi Foto Motor Sisi 1 Telah Dihapus`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  function HapusM2() {
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_vphoto_sisi2`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Revisi Foto Motor Sisi 2 Telah Dihapus`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  //Delete Bukti Biaya Kirim
  function HapusBukti_BK() {
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_bukti_biaya_kirim`,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        RequestPenemuan();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Bukti Biaya Kirim Telah Dihapus`,
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      });
  }

  return (
    <div>
      <AlertList showIcon={false} alerts={alerts} />
      <DialogHapusBatal
        open={open2}
        close={handleClose2}
        title="Verifikasi STNK Voucher"
        message="Anda Yakin Ingin Verifikasi Voucher Ini ?"
        btn="Verifikasi"
        btn2="Batal"
        // type="okbatal"
        fun={Verifikasi}
      />
      <DialogHapusBatal
        open={open}
        close={handleClose}
        title="Approve Voucher"
        message="Anda Yakin Ingin Approve Voucher Ini ?"
        btn="Approve"
        btn2="Batal"
        // type="okbatal"
        fun={Approve}
      />
      <DialogHapusBatal
        open={open1}
        close={handleClose1}
        title="Reject Voucher"
        message="Anda Yakin Ingin Reject Voucher Ini ?"
        btn="Reject"
        btn2="Batal"
        // type="okbatal"
        fun={Reject}
      />
      <DialogHapusBatal
        open={approveOrder}
        close={handleCloseApproveOrder}
        title="Approve Order"
        message="Anda Yakin Ingin Approve Order Ini ?"
        btn="Approve"
        btn2="Batal"
        // type="okbatal"
        fun={ApproveOrder}
      />
      <DialogRejectOrderVoucher
        open={rejectOrder}
        close={handleCloseRejectOrder}
        title="Reject Order"
        submit={RejectOrder}
      />

      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <AntTabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className={classes.title}
            >
              <AntTab label="Data Kendaraan" {...a11yProps(0)} />
              <AntTab label="Order Details" {...a11yProps(1)} />
              {hide && <AntTab label="Dokumen Verifikasi" {...a11yProps(2)} />}
            </AntTabs>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <AlertList showIcon={false} alerts={alerts1} />
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0}>
            <Portlet>
              <PortletHeader title="Data Kendaraan"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor Polisi
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_vplate" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor Rangka
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_rangka" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor Mesin
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_mesin" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Type Motor/Mobil
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_vtype" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Type
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_ptype" disabled />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nama STNK/BPKB
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" id="tbpkb_vdoc_name" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor Mesin
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" id="tbpkb_mesin" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Brand
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" id="tbpkb_vbrand" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Warna
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" id="tbpkb_vcolor" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Tahun
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" id="tbpkb_vyear" disabled />
                      </div>
                    </div>
                  </Col>
                </Row>
              </PortletBody>
              <PortletHeader title="Profile Debitur"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nama Debitur
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_debitur_name"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Alamat Konsumen
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_address" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor KTP
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_ktp_debitur" disabled />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor KK
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_kk_debitur" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Tanggal Mulai Tunggak
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tbpkb_created_date" disabled />
                      </div>
                    </div>
                  </Col>
                </Row>
              </PortletBody>
              <PortletHeader title="Rincian Biaya"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Base Price
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_base_price"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Bunga
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_bunga"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Denda
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_denda"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Discount
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_discount"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Total
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_bp_total"
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Pelunasan Debitur
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_pelunasan_debitur"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Pelunasan Leasing
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_pelunasan_debitur"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Refund
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_refund"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Upping
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="tbpkb_upping"
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </PortletBody>

              <Portlet id="data_informan">
                <PortletHeader title="Data Informan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">
                          Nama Informan
                        </label>
                        <div class="col-sm-7">
                          <input
                            type="text"
                            class="form-control"
                            id="mmle_name"
                            disabled
                          />
                          <input type="hidden" id="mmle_id" name="mmle_id" />
                          <input type="hidden" id="mper_id" name="mper_id" />
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">
                          No HP Informan
                        </label>
                        <div class="col-sm-7">
                          <input
                            type="text"
                            class="form-control"
                            id="mper_phone"
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
            </Portlet>
            <Row>
              <Col md>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}>
                  <i class="fas fa-arrow-left"></i>Kembali
                </button>
              </Col>
            </Row>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Portlet>
              <PortletHeader title="Profile Konsumen"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nama Konsumen
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="nama_konsumen"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Alamat Konsumen
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="alamat_konsumen" disabled />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nomor HP
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="no_hp_konsumen" disabled />
                      </div>
                    </div>
                  </Col>
                </Row>
              </PortletBody>
              <PortletHeader title="Order Detail"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Tanggal Transfer
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="tanggal_transfer" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Pelunasan Konsumen
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="nominal_pelunasan_konsumen" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Sisa Denda
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="sisa_denda" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Sisa Hutang
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="sisa_hutang" disabled />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Sisa Pelunasan
                      </label>
                      <div className="col-sm-7">
                        <input type="text" className="form-control" id="sisa_pelunasan" disabled />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Nominal Transfer
                      </label>
                      <div className="col-sm-8">
                        <input type="text" class="form-control" id="nominal_transfer" disabled />
                      </div>
                    </div>
                    <div
                      className="form-group row"
                      id="validasi_mobil"
                      style={{ marginTop: "-15px" }}
                    >
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Foto Bukti Transfer
                      </label>
                      <div className="col-sm-8">
                        <a
                          type="button"
                          className="btn btn-outline-brand btn-icon"
                          id="photo_bukti_transfer"
                          target="_blank"
                          disabled="disabled"
                        >
                          <i className="far fa-image"></i>
                        </a>
                      </div>
                    </div>
                    <Row id="detail_mobil">
                      <Col>
                        <div className="form-group row" style={{ marginTop: "-15px" }}>
                          <label for="inputEmail3" className="col-sm-4 col-form-label">
                            Foto Kendaraan 1
                          </label>
                          <div className="col-sm-8">
                            <a
                              type="button"
                              className="btn btn-outline-brand btn-icon"
                              id="photo_kendaraan1"
                              target="_blank"
                              disabled="disabled"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: "-15px" }}>
                          <label for="inputEmail3" className="col-sm-4 col-form-label">
                            Foto Kendaraan 2
                          </label>
                          <div className="col-sm-8">
                            <a
                              type="button"
                              className="btn btn-outline-brand btn-icon"
                              id="photo_kendaraan2"
                              target="_blank"
                              disabled="disabled"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {!hide && <Row>
                      <Col md>
                        <button
                          type="button"
                          className="btn btn-success btn-elevate pull-right"
                          id="idApprove"
                          onClick={handleClickApproveOrder}
                        >
                          <i class="fas fa-check"></i> Approve
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-elevate pull-right mr-1"
                          id="idReject"
                          onClick={handleClickRejectOrder}
                        >
                          <i class="fas fa-times"></i> Reject
                        </button>
                      </Col>
                    </Row>}
                  </Col>
                </Row>
              </PortletBody>
            </Portlet>
            <Row>
              <Col md>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}>
                  <i class="fas fa-arrow-left"></i>Kembali
                </button>
              </Col>
            </Row>
          </TabPanel>
          {hide && <TabPanel value={value} index={2}>
            <Portlet>
              <PortletHeader title="Dokumen Verifikasi"></PortletHeader>
              <PortletBody>
                <Row>
                  <Col md={6}>
                    <div class="form-group row">
                      <label for="inputEmail3" class="col-sm-4 col-form-label">
                        Nama Informan
                      </label>
                      <div class="col-sm-7">
                        <input
                          type="text"
                          class="form-control"
                          id="mmle_name_verif"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" class="col-sm-4 col-form-label">
                        No HP Informan
                      </label>
                      <div class="col-sm-7">
                        <input
                          type="text"
                          class="form-control"
                          id="mper_phone_verif"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" className="col-sm-4 col-form-label">
                        Fee Informan
                      </label>
                      <div className="col-sm-7">
                        <input type="text" class="form-control" id="fee_informan" disabled />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div class="form-group row">
                      <label for="inputEmail3" class="col-sm-4 col-form-label">
                        Lat-Long Informan
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          id="tpif_lat-long_informan_verif"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="form-group row" style={{ marginTop: "-15px" }}>
                      <label for="inputEmail3" class="col-sm-4 col-form-label">
                        Alamat Posisi Informan
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          id="tpif_address_informan_verif"
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <button
                      type="button"
                      className="btn btn-warning btn-elevate"
                      onClick={props.close}
                    >
                      <i class="fas fa-arrow-left"></i>Kembali
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-elevate pull-right"
                      id="idApprove"
                      onClick={handleClickOpen}
                    >
                      <i class="fas fa-check"></i> Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-elevate pull-right mr-1"
                      id="idReject"
                      onClick={handleClickOpen1}
                    >
                      <i class="fas fa-times"></i> Reject
                    </button>
                    <button
                      type="button"
                      className="btn btn-brand btn-elevate pull-right"
                      id="idVerifikasi"
                      onClick={handleClickOpen2}
                    >
                      <i className="fas fa-check-double"></i>Verifikasi
                    </button>
                  </Col>
                </Row>
                {/* <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={6}>
                    </Col>
                  </Row> */}
              </PortletBody>
            </Portlet>
          </TabPanel>}
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
