/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import "datatables.net-bs4";
import { Portlet, PortletBody, PortletHeader } from "../../partials/content/Portlet";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Check from "@material-ui/icons/Check";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PaymentIcon from "@material-ui/icons/Payment";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import API from "../API";
import DetailVoucherVerifikasi from "./Utils/DetailVoucherVerfikasi";
import DetailVoucherVerifikasiBPKB from "./Utils/DetailVoucherVerfikasiBPKB";
import Utils from "./Utils/FunctionDetail";
import { AlertList } from "react-bs-notifier";
import DialogVerifikasiPricing from "./Utils/DialogVerifikasiPricing";
import DialogEditDP from "./Utils/DialogEditDP";
import { getFeeMitra } from "../../functions/invoice";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 35,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient( 95deg,#153264 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,#153264 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 70,
    height: 70,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "linear-gradient( 136deg, #153264 0%, #153264 50%, #153264 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentIcon fontSize="large" color="secondary" />,
    2: <AssignmentTurnedInIcon fontSize="large" />,
    3: <PaymentIcon fontSize="large" />,
    4: <DoneAllIcon fontSize="large" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <Check className={classes.completed} fontSize="large" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return [
    "Menunggu Persetujuan",
    "Voucher Diapprove",
    "Menunggu Pembayaran",
    "Voucher Sudah Lunas",
  ];
}

export default function VoucherVerifikasi() {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [openDetailBPKB, setOpenDetailBPKB] = React.useState(false);
  const [tpnmBPKBID, setTpnmBPKBID] = React.useState(null);
  const [tpnmBPKB, setTpnmBPKB] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [alerts, setAlerts] = React.useState([]);
  const [id, setId] = React.useState(null);
  const [idTpnm, setIdTpnm] = React.useState(null);
  const [idTvoc, setIdTvoc] = React.useState(null);
  const [tvoc, setTvoc] = React.useState(null);
  const [tvoctype, setTvoctype] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [koordinator_mper_id, setkoordinator_mper_id] = React.useState(null);
  const [mmle_id, setmmle_id] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [cadangan_korwil, setcadangan_korwil] = React.useState(null);
  const [potongan_minimum, setpotongan_minimum] = React.useState(null);
  const [potongan_persen, setpotongan_persen] = React.useState(null);
  const [potongan_stnk, setpotongan_stnk] = React.useState(null);
  const [idTvoc2, setIdTvoc2] = React.useState(null);
  const formatter = new Intl.NumberFormat("de-DE");

  useEffect(() => {
    $(document).ready(function() {
      TableVerifikasi();
      $(document).on("click", "#details-bpkb", function() {
        setOpenDetailBPKB(true);
        setIdTvoc($(this).attr("data-tvoc_id"));
        setTpnmBPKBID($(this).attr("data-tpnm_id"));
        setTpnmBPKB($(this).attr("data-tvoc-vehicle-number"));
      });
      $(document).on("click", "#delete", function() {
        var tvoc_id = $(this).attr("data-id");
        var settings = {
          async: true,
          crossDomain: true,
          url: API.Financeapi + "v1/Voucher_verifikasi/delete_voucher/" + tvoc_id,
          method: "GET",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
        };
        $.ajax(settings)
          .done(function(response) {
            // setOpenConfirmHapusKoordinator(false);
            // setId(null);
            TableVerifikasi();
            setAlerts([
              {
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Voucher Telah Dihapus",
              },
            ]);
            setTimeout(() => {
              setAlerts([]);
            }, 3000);
            return 0;
          })
          .fail(function(response) {
            console.log(response);
            // setOpenConfirmHapusKoordinator(false);
            // setId(null);
            setAlerts([
              {
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]",
              },
            ]);
            setTimeout(() => {
              setAlerts([]);
            }, 3000);
            API.Handle403(response, setAlerts);
            return 0;
          });
      });
      $(document).on("click", "#details", function() {
        handleClickOpen();
        setId($(this).attr("data-id"));
        setTvoc($(this).attr("data-tvoc"));
        setTvoctype($(this).attr("data-type"));
        setType($(this).attr("data-tvoctype"));
        setAmount($(this).attr("data-fee"));
      });
      $(document).on("click", "#pricing", function() {
        var tvocpricing = {
          async: true,
          crossDomain: true,
          url:
            API.Financeapi +
            "v1/Voucher_verifikasi/getTvocPricing/" +
            $(this).attr("data-tvoc") +
            "/" +
            $(this).attr("data-id"),
          method: "GET",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
        };
        $.ajax(tvocpricing)
          .done(function(response) {
            console.log(response);
            if (response.data != null) {
              setmmle_id(response.data.mmle_id);
              setkoordinator_mper_id(response.data.koordinator_mper_id);
              setcadangan_korwil(response.data.cadangan_korwil);
              setpotongan_minimum(response.data.potongan_minimal);
              setpotongan_persen(response.data.potongan_percent);
              setpotongan_stnk(response.data.potongan_stnk);
              setIdTpnm(response.data.tpnm_id);
              setIdTvoc(response.data.tvoc_id);
              setType(response.data.tvoc_type);
              // setAmount(response.data.potongan_stnk);

              handleClickOpen1();

              if (
                response.data.tpnm_base_price_revisi != null &&
                response.data.tpnm_base_price_revisi != ""
              ) {
                $("#price_tpnm_base_price").val(
                  formatter.format(parseInt(response.data.tpnm_base_price_revisi))
                ); //revisi bp
              } else {
                $("#price_tpnm_base_price").val(
                  formatter.format(parseInt(response.data.tpnm_base_price))
                );
              }
              if (
                response.data.tvoc_ammount_revisi != null &&
                response.data.tvoc_ammount_revisi != ""
              ) {
                $("#price_tpnm_fee_mitra").val(
                  formatter.format(parseInt(response.data.tvoc_ammount_revisi))
                ); //revisi fee mitra
              } else {
                $("#price_tpnm_fee_mitra").val(
                  formatter.format(parseInt(response.data.tvoc_ammount))
                );
              }
              if (
                response.data.tvoc_fee_stnk_revisi != null &&
                response.data.tvoc_fee_stnk_revisi != ""
              ) {
                $("#price_tpnm_fee_stnk").val(
                  formatter.format(parseInt(response.data.tvoc_fee_stnk_revisi))
                ); //revisi bp stnk
              } else {
                $("#price_tpnm_fee_stnk").val(
                  formatter.format(parseInt(response.data.tvoc_fee_stnk))
                );
              }
              var bp_stnk = response.data.pricing_bp_stnk
                ? response.data.pricing_bp_stnk
                : response.data.tvoc_fee_stnk;
              $("#price_tpnm_bp_stnk").val(formatter.format(parseInt(bp_stnk))); //bp stnk
              $("#pendapatan-lain-lain").val(
                formatter.format(parseInt(response.data.pendapatan_lain_lain))
              ); //bp stnk
              $("#pic_tagih").val(response.data.pic_tagih); //revisi fee mitra
              // $("#price_rev_fee_mitra").val(""); //revisi fee mitra
              // $("#price_rev_fee_stnk").val(""); //revisi fee stnk
              if (
                response.data.tvoc_biaya_kirim_estimasi != null &&
                response.data.tvoc_biaya_kirim_estimasi != ""
              ) {
                $("#price_tpnm_biaya_kirim_estimasi")
                  .val(response.data.tvoc_biaya_kirim_estimasi)
                  .trigger("change");
                // $("#price_tpnm_biaya_kirim_estimasi").val(
                // formatter.format(parseInt(response.data.tvoc_biaya_kirim_estimasi))
                // );
              } else {
                $("#price_tpnm_biaya_kirim_estimasi")
                  .val(0)
                  .trigger("change");
              }
              $("#transit_pool").append(
                "<option value='" +
                  response.data.transit_pool +
                  "'>" +
                  response.data.transit_pool +
                  "<option>"
              );
              if (response.data.tvoc_status_kirim == "Siap Tagih") {
                $("#status-kirim").prop("checked", true);
              } else {
                $("#status-kirim").prop("checked", false);
              }
              $("#transit_pool")
                .val(response.data.transit_pool)
                .trigger("change");
              if (response.data.koordinator_mper_id !== null) {
                // $("#cadangan_korwil_temp").val(response.data.cadangan_korwil);
                $("#transit_pool").attr("disabled", "disabled");
              } else {
                $("#transit_pool").removeAttr("disabled");
                $("#cadangan_korwil").val(0);
              }
              // if (
              //   response.data.tvoc_biaya_kirim_real != null &&
              //   response.data.tvoc_biaya_kirim_real != ""
              // ) {
              //   $("#price_tpnm_biaya_kirim_real").val(
              //     formatter.format(parseInt(response.data.tvoc_biaya_kirim_real))
              //   );
              // } else {
              //   $("#price_tpnm_biaya_kirim_real").val("0");
              // }
              // if (
              //   response.data.tvoc_biaya_kirim_subleasing != null &&
              //   response.data.tvoc_biaya_kirim_subleasing != ""
              // ) {
              //   $("#price_tpnm_biaya_kirim_subleasing").val(
              //     formatter.format(parseInt(response.data.tvoc_biaya_kirim_subleasing))
              //   );
              // } else {
              //   $("#price_tpnm_biaya_kirim_subleasing").val("0");
              // }
              // if (
              //   response.data.tvoc_biaya_kirim_submitra != null &&
              //   response.data.tvoc_biaya_kirim_submitra != ""
              // ) {
              //   $("#price_tpnm_biaya_kirim_submitra").val(
              //     formatter.format(parseInt(response.data.tvoc_biaya_kirim_submitra))
              //   );
              // } else {
              //   $("#price_tpnm_biaya_kirim_submitra").val("0");
              // }
            }
          })
          .fail(function(response) {
            API.Handle403(response, setAlerts);
            return 0;
          });
      });
      $(document).on("click", "#dp", function() {
        handleClickOpen2();
        setIdTvoc2($(this).attr("data-id"));
      });
    });
  }, [setAlerts]);

  const TableVerifikasi = () => {
    $("#voucher-main").DataTable({
      pagingType: "full_numbers",
      aaSorting: [],
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Financeapi + "v1/Voucher_verifikasi/index",
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        // console.log(aData.tvoc_type)
        if (aData.tvoc_status == "Voucher sudah diisi") {
          $("td:eq(10)", nRow).css("background-color", "#81D4FA");
        } else if (aData.tvoc_status == "Siap Dibayar") {
          $("td:eq(10)", nRow).css("background-color", "#FFE800");
        } else if (aData.tvoc_status == "Ditolak") {
          $("td:eq(10)", nRow).css("background-color", "#FD333F");
        } else if (aData.tvoc_status == "Telah Dibayar") {
          $("td:eq(10)", nRow).css("background-color", "#6CFF00");
        }
        if (aData.tvoc_type == "Referall") {
          $("td:eq(1)", nRow).css("background-color", "#9999ff");
          $("td:eq(9)", nRow).css("background-color", "#9999ff");
        }
        if (aData.app_type == "mobil") {
          $("td:eq(0)", nRow).css("background-color", "#AFEEEE");
        } else if (aData.app_type == "motor") {
          $("td:eq(0)", nRow).css("background-color", "#F4A460");
        } else if (aData.app_type == "bpkb") {
          $("td:eq(0)", nRow).css("background-color", "#b5c9fa");
        }
      },
      columns: [
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return "<div>" + Utils.StatusAppType(full.app_type) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var type_user;
            if (full.tvoc_informan_type == "mitra" && full.tpif_id != null) {
              type_user = "Mitra - Informan";
            } else {
              type_user = full.tvoc_informan_type;
            }
            return " <div > " + type_user + "</div>";
          },
        },
        {
          data: "tvoc_created_date",
        },
        {
          data: "tvoc_created_time",
        },
        {
          data: "tvoc_account_name",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var fee_mitra =
              full.tvoc_ammount_revisi != null ? full.tvoc_ammount_revisi : full.tvoc_ammount;
            var fee_stnk =
              full.tvoc_fee_stnk_revisi != null ? full.tvoc_fee_stnk_revisi : full.tvoc_fee_stnk;
            var fee = getFeeMitra(full.tvoc_status_stnk, fee_mitra, fee_stnk);

            console.log("fee: " + fee);
            console.log("status_stnk: " + full.tvoc_status_stnk);
            return " <div >" + formatter.format(fee) + "</div>";
          },
        },
        {
          data: "tvoc_vehicle_number",
        },
        {
          data: "mmle_name",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return (
              " <div > " +
              full.tpnm_type +
              " " +
              full.tpnm_vyear +
              " " +
              full.tpnm_vcolor +
              "</div>"
            );
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + full.tvoc_type + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + full.tvoc_status + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            // console.log(full)
            if (full.app_type === "bpkb") {
              return `
              <button id="ready1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="ready1">
              <button class='dropdown-item' id='pricing-bpkb'><i class="fas fa-money-check-alt"></i>Edit Pricing</button>
              <button class='dropdown-item' id='details-bpkb' data-tvoc_id="${full.tvoc_id}" data-tpnm_id="${full.tpnm_id}" data-tvoc-vehicle-number="${full.tvoc_vehicle_number}"><i class='fa fa-eye'></i>Detail</button>
             </div>`;
            } else if (full.tvoc_informan_type == "mitra") {
              return `
                <button id="ready1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="ready1">
                <button class='dropdown-item' id='pricing' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-toggle='tooltip' title='Reset Password'><i class="fas fa-money-check-alt"></i>Edit Pricing</button>
                <button class='dropdown-item' id='details' data-type=${full.tvoc_type} data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-tvoc-account-name="${full.tvoc_account_name}"  data-tvoc-type="${full.tvoc_type}" data-tvoc-bank-name="${full.tvoc_bank_name}" data-tvoc-account-number="${full.tvoc_account_number}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail</button>
                <button class='dropdown-item' id='dp' data-id="${full.tvoc_id}" data-toggle='tooltip' title='Reset Password'><i class="fas fa-location-arrow"></i>Edit Drop Point</button>  
                <button class='dropdown-item' id='delete' data-id="${full.tvoc_id}" data-toggle='tooltip' title='Delete'><i class="fas fa-trash"></i>Delete</button>
               </div>`;
            } else {
              return (
                "<button class='details btn btn-outline-brand btn-icon btn-sm' id='details' data-type=" +
                full.tvoc_type +
                " data-id=" +
                full.tpnm_id +
                " data-tvoc=" +
                full.tvoc_id +
                " data-tvoctype=" +
                full.tvoc_informan_type +
                " data-fee=" +
                full.tvoc_ammount +
                " data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>"
              );
            }
            // ini untuk edit droppoint
            //  <button class='dropdown-item' id='dp' data-id="${full.tvoc_id}" data-toggle='tooltip' title='Reset Password'><i class="fas fa-location-arrow"></i>Edit Drop Point</button>
          },
        },
      ],
    });

    $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
  };

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    TableVerifikasi();
  }

  function handleClickOpen1() {
    setOpen1(true);
  }
  function handleClose1() {
    setOpen1(false);
    $('[type="submit"]').prop("disabled", false);
    TableVerifikasi();
  }
  function handleClickOpen2() {
    setOpen2(true);
  }
  function handleClose2() {
    setOpen2(false);
    TableVerifikasi();
  }

  //  function UpdatePricingxx() {
  //   if ($("#price_tpnm_fee_stnk").val() == "") {
  //     url = `${API.Financeapi}/v1/Voucher_verifikasi/editPricingTvoc/${idTpnm}/${idTvoc}?tpnm_fee_stnk_revisi=${$("#price_tpnm_fee_stnk").val()}`
  //   }else{
  //     url = `${API.Financeapi}/v1/Voucher_verifikasi/editPricingTvoc/${idTpnm}/${idTvoc}?tpnm_fee_stnk_revisi=${$("#price_tpnm_fee_stnk").val()}`
  //   }

  //   var data = {
  //     'tvoc_ammount_revisi': $("#price_tvoc_ammount").val(),
  //     'tpnm_base_price_revisi': $("#price_tpnm_base_price").val(),
  //   }

  //   var settings = {
  //     "async": true,
  //     "crossDomain": true,
  //     "url": url,
  //     "method": "POST",
  //     "headers": {
  //     "Authorization": "Baisc "+localStorage.getItem('jwt')
  //     },
  //     "processData": false,
  //     "contentType": false,
  //     "mimeType": "multipart/form-data",
  //     "data": JSON.stringify(data)
  //   }

  //   $.ajax(settings).done(function (response) {
  //       handleClose1()
  //       setAlerts([{
  //           id: 1,
  //           type: "success",
  //           headline: "Berhasil",
  //           message: "Pricing Telah Diubah"
  //       }])
  //       setTimeout(() => {
  //           setAlerts([])
  //       }, 3000)
  //   }).fail(function (response) {
  //       setAlerts([{
  //           id: 1,
  //           type: "danger",
  //           headline: "Gagal",
  //           message: "Terjadi Kendala [Error Code "+response.status+"]"
  //       }])
  //       setTimeout(() => {
  //           setAlerts([])
  //       }, 3000)
  //       API.Handle403(response,setAlerts)
  //   })
  //  }

  function UpdateDP() {
    var url = `${API.Financeapi}/v1/Voucher_verifikasi/editDP/${idTvoc2}/${$(
      "#tpnm_delivery_dpname"
    ).val()}`;
    var data = {
      tpnm_delivery_dpname: $("#tpnm_delivery_dpname").val(),
    };
    var settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: JSON.stringify(data),
    };

    $.ajax(settings)
      .done(function(response) {
        handleClose2();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Droppoint Telah Diubah",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
      });
  }

  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <DialogVerifikasiPricing
        open={open1}
        close={handleClose1}
        title={"Edit Pricing Voucher"}
        btn={"Edit"}
        idTvoc={idTvoc}
        idTpnm={idTpnm}
        mmle_id={mmle_id}
        koordinator_mper_id={koordinator_mper_id}
        cadangan_korwil={cadangan_korwil}
        potongan_minimum={potongan_minimum}
        potongan_persen={potongan_persen}
        potongan_stnk={potongan_stnk}
        setAlerts={setAlerts}
        // submit={UpdatePricing}
        fee={amount}
      />
      <DialogEditDP
        open={open2}
        close={handleClose2}
        title={"Edit Drop Point"}
        btn={"Edit"}
        fun={UpdateDP}
      />
      <DetailVoucherVerifikasi
        open={open}
        close={handleClose}
        id={id} //tpnm_id
        step={6} //step
        tvoctype={tvoctype} //referred / referall
        tvoc={tvoc} //tvoc_id
        type={type} // mitra/informan
        fee={amount} //amount
      />
      <DetailVoucherVerifikasiBPKB
        open={openDetailBPKB}
        close={() => setOpenDetailBPKB(false)}
        tvoc_id={idTvoc} //tpnm_id
        tpnmBPKBID={tpnmBPKBID} //tpnm_id
        tpnmBPKB={tpnmBPKB} //tpnm
        // step={6} //step
        // tvoctype={tvoctype} //referred / referall
        // tvoc={tvoc} //tvoc_id
        // type={type} // mitra/informan
        // fee={amount} //amount
      />

      <Portlet>
        <PortletHeader title="Step Voucher"></PortletHeader>
        <PortletBody>
          <Grid container spacing={3}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              style={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletHeader title="Data Voucher Verifikasi" />
        <PortletBody>
          <table
            className="table table-hover table-responsive-md"
            id="voucher-main"
            style={{ width: "100%" }}
          >
            <thead className="bg-primary" style={{ color: "white" }}>
              <tr>
                <th scope="col">Tipe</th>
                <th scope="col">Tipe User</th>
                <th scope="col">Tanggal</th>
                <th scope="col">Jam</th>
                <th scope="col">Nama</th>
                <th scope="col">Harga</th>
                <th scope="col">Nopol</th>
                <th scope="col">Leasing</th>
                <th scope="col">Unit</th>
                <th scope="col">Tipe Voucher</th>
                <th scope="col">Status</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
          </table>
          <div>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#F4A460" }}
            ></button>
            <span style={{ color: "black" }}> : Motor </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#AFEEEE" }}
            ></button>
            <span style={{ color: "black" }}> : Mobil </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#9999ff" }}
            ></button>
            <span style={{ color: "black" }}> : Referall </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#81D4FA" }}
            ></button>
            <span style={{ color: "black" }}> : Voucher sudah diisi</span>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
